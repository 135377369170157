export const environment = {
    APP_VERSION_MAJOR: 1,
    APP_VERSION_MINOR: 1,
    APP_VERSION_PATCH: 1,

    production: true,
    staging: false,

    NODE_SERVER_URL: 'https://api-node.live.engincloud.com/api',
};
